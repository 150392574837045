import axios from "axios";
import api from "../../api/api";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { Country, State, City } from "country-state-city";
import Footer from "../../components/Footer";
import useDetails from "../../hooks/useDetails";
import { getCurrentUser } from "../../utils/authService";
import avatar from "../../assets/images/user.png"
import $ from 'jquery';
import 'dropify/dist/css/dropify.min.css';
import 'dropify';

function UpdateBioData() {
  const [details, setDetails] = useState();
  const [file, setFile] = useState();
  const [bio, setBio] = useState();
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(data?.country_origin);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState(data?.state_origin);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(data?.lga_origin);
  const [title, setTitle] = useState(data?.title);
  const [surname, setSurname] = useState(data?.surname);
  const [othername, setOthername] = useState(data?.othername);
  const [username, setUsername] = useState(data?.username);
  const [passport, setPassport] = useState(data?.photo);
  const [dob, setDob] = useState(data?.dob);
  const [mob, setMob] = useState(data?.mob);
  const [yob, setYob] = useState(data?.yob);
  const [religion, setReligion] = useState(data?.religion)
  const [maritalStatus, setMaritalStatus] = useState(data?.marital_status)

  const [nokTitle, setNokTitle] = useState("");
  const [nokEmail, setNokEmail] = useState("");
  const [nokSurname, setNokSurname] = useState("");
  const [nokOthername, setNokOthername] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nokAddress, setNokAddress] = useState("");
  const [nokPhone, setNokPhone] = useState("");

  const [nokCountry, setNokCountry] = useState("");
  const [nokState, setNokState] = useState("");
  const [nokCity, setNokCity] = useState("");

  
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const navi = useNavigate();

  const user = getCurrentUser();

  const location = useLocation()

  const {staging: stage, data: detailsData} = useDetails()

  const inputRef = useRef(null);


  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const updateBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/update/${user.application_id}`,
        {
          email: email,
          phone: phone,
          surname,
          othername,
          username,
          title,
          dob,
          mob,
          yob,
          country_origin: selectedCountry,
          state_origin: selectedState,
          lga_origin: selectedCity,
          gender,
          religion,
          marital_status: maritalStatus
        }
      );
      console.log(res);
      toast.success("BioData updated", successProp);
      // getDetails();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const updateNokBio = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await api.patch(
        `/admissions/applicant/nok/update/${user.application_id}/`,
        {
          email: nokEmail,
          phone: nokPhone,
          surname: nokSurname,
          othername: nokOthername,
          title: nokTitle,
          country: nokCountry,
          state: nokState,
          city: nokCity,
          address: nokAddress,
        }
      );
      console.log(res);
      toast.success("Next of kin biodata updated", successProp);
      // getDetails();
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const handleSelectGender = (event) => {
    console.log(event.target.value);
    setGender(event.target.value);
  };
  
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const country = [...new Set(data.map((item) => item.country))].sort();
  console.log("Overall data is", data);

  const handleCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setSelectedCountry(coun);
      console.log("Selected Country is", coun);
      let states = NaijaStates.states();
      console.log("The states noww", states);
      setState(states);
    } else {
      setSelectedCountry(coun);
      console.log("Selected Country is", coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleState = (e) => {
    let st = e.target.value;

    if (selectedCountry === "Nigeria") {
      setSelectedState(st);
      console.log("Selected state is", st);
      let strValue = st.toString();
      console.log("String", strValue);
      const dCities = NaijaStates.lgas(strValue);
      console.log("dCities", dCities);
      setCities(dCities.lgas);
    } else {
      setSelectedState(st);
      console.log("Selected state is", st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      console.log(cities);
      setCities(cities);
    }
  };

  const handleCity = (e) => {
    let ct = e.target.value;
    console.log(ct);
    setSelectedCity(ct);
  };

  // Nok of kin country, state and city handler

  const handleNokCountry = (e) => {
    let coun = e.target.value;

    if (coun === "Nigeria") {
      setNokCountry(coun);
      console.log("Selected Country is", coun);
      let states = NaijaStates.states();
      console.log("The states noww", states);
      setState(states);
    } else {
      setNokCountry(coun);
      console.log("Selected Country is", coun);
      let states = data.filter((state) => state.country === coun);
      states = [...new Set(states.map((state) => state.subcountry))].sort();
      setState(states);
    }
  };

  const handleNokState = (e) => {
    let st = e.target.value;

    if (nokCountry === "Nigeria") {
      setNokState(st);
      console.log("Selected state is", st);
      let strValue = st.toString();
      console.log("String", strValue);
      const dCities = NaijaStates.lgas(strValue);
      console.log("dCities", dCities);
      setCities(dCities.lgas);
    } else {
      setNokState(st);
      console.log("Selected state is", st);
      let cities = data.filter((city) => city.subcountry === st);
      cities = [...new Set(cities.map((city) => city.name))];
      console.log(cities);
      setCities(cities);
    }
  };

  const handleNokCity = (e) => {
    let ct = e.target.value;
    console.log(ct);
    setNokCity(ct);
  };

  // const getDetails = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await GetApplicantDetails(user.application_id);
  //     console.log("Details", data);
  //     setPassport(data.photo);
  //     setTitle(data.title);
  //     setSurname(data.surname);
  //     setOthername(data.othername);
  //     setEmail(data.email);
  //     setPhone(data.phone);
  //     setUsername(data.username);
  //     setGender(data.gender);
  //     setSelectedCity(data.lga_origin);
  //     setSelectedState(data.state_origin);
  //     setSelectedCountry(data.country_origin);
  //     setDob(data.dob);
  //     setMob(data.mob);
  //     setYob(data.yob);
  //     setReligion(data.religion)
  //     setMaritalStatus(data.marital_status)
  //     setLoading(false);
  //     setDetails(data);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
;

  const onImageError = (e) => {
    e.target.src = avatar
  };

  const handlePassportChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleBiodataUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("photo", file?file:'');
    formData.append("yob", yob?yob:'');
    formData.append("mob", mob?mob:'');
    formData.append("dob", dob?dob:'');
    formData.append("religion", religion?religion:'');
    formData.append("phone", phone?phone:'');
    formData.append("marital_status", maritalStatus?maritalStatus:'');
    formData.append("email", email?email:'')

    try {
      setLoading(true)
      toast.loading("Updating Biodata...")
      const {data} = await api.patch(`/student/biodata-update/${user}`, formData);
      console.log("Updated biodata",data)
      toast.success("Update successful")
      setLoading(false)
      window.location.reload()
    } catch (error) {}
  };

  useEffect(() => {
    // getDetails();
    // fetchPaymentStatus()
  }, [passport]);

  useEffect(() => {
    $(inputRef.current).dropify();
  });

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  } 
  if (stage === "completed" || stage === "rejected" || stage === "approved") {
    return (
      <Navigate
        to={{
          pathname: `/apply`,
          state: { from: location },
        }}
        replace
      />
    );
  } 

  return (
    <div class="page-wrapper">
      {detailsData && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Update Biodata</li>
                  </ol>
                </div>
                <h4 class="page-title">Update Biodata</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body  met-pro-bg">
                  <div class="met-profile">
                    <div class="row">
                      <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                        <div class="met-profile-main">
                          <div class="met-profile-main-pic">
                            <img
                              src={detailsData?.photo ? detailsData?.photo : avatar}
                              onError={onImageError}
                              alt=""
                              className="rounded-circle"
                              style={{height: "100px", objectFit:'contain'}}
                            />
                            <span class="fro-profile_main-pic-change">
                              <i class="fas fa-camera"></i>
                            </span>
                          </div>
                          <div class="met-profile_user-detail">
                            <h5 class="met-user-name">
                              {detailsData
                                ? detailsData?.surname + " " + detailsData?.othername
                                : ""}
                            </h5>
                            <p class="mb-0 met-user-name-post">Student</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 ml-auto">
                        <ul class="list-unstyled personal-detail">
                          <li class="">
                            <b> Matric Number </b> : {detailsData ? detailsData?.matric_no : ""}
                          </li>
                          <li class="mt-2">
                            <b> Application Number </b> : {detailsData ? detailsData?.application_no : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12 mx-auto">
                      <div class="card">
                        <div class="card-body">
                          <form onSubmit={handleBiodataUpdate} class="card-box">
                            <div class="col-lg-12">
                              <div class="card-body">
                                <h4 class="mt-0 header-title">
                                  Passport Upload
                                </h4>
                                <input
                                  type="file"
                                  ref={inputRef}
                                  id="input-file-now"
                                  onChange={handlePassportChange}
                                  className="dropify"
                                  disabled={loading}
                                />
                              </div>
                            </div>

                          <div class="">
                              <div class="form-group row">
                                {/* <div className="col-md-2">
                                  <label>Title</label>
                                  <select
                                    class="form-control"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  >
                                    <option>
                                      {title ? title : "--Title--"}
                                    </option>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Miss</option>
                                  </select>
                                </div> */}
                                <div class="col-md-6">
                                  <label>Surname</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.surname}
                                    class="form-control"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>Other names</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.othername}
                                    class="form-control"
                                    value={othername}
                                    onChange={(e) =>
                                      setOthername(e.target.value)
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Matric Number</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.matric_no}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>Application/Registration Number</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.application_no}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Email</label>
                                  <input
                                    type="email"
                                    placeholder={detailsData?.email}
                                    class="form-control"
                                    name="example-email"
                                    id="example-email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>Phone Number</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.phone}
                                    class="form-control"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Programme</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.programme}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>faculty</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.faculty}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Department</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.department}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>Course of Study</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.course_of_study}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Entry Session</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.entry_session}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                                <div class="col-md-6">
                                  <label>Current Level</label>
                                  <input
                                    type="text"
                                    placeholder={detailsData?.level}
                                    class="form-control"
                                    disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <label>Gender</label>
                                  <select
                                    class="form-control"
                                    value={gender}
                                    onChange={handleSelectGender}
                                    disabled
                                  >
                                    <option>{detailsData?.gender? detailsData?.gender : "--Gender--"}</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-4">
                                  <label>Country</label>
                                  <select
                                    class="form-control"
                                    disabled
                                  >
                                    <option>{detailsData?.country_origin? detailsData?.country_origin : "--Gender--"}</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label>State</label>
                                  <select
                                    class="form-control"
                                    disabled
                                  >
                                    <option>{detailsData?.state_origin? detailsData?.state_origin : "--Gender--"}</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label>LGA</label>
                                  <select
                                    class="form-control"
                                    disabled
                                  >
                                    <option>{detailsData?.lga_origin? detailsData?.lga_origin : "--Gender--"}</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Marital Status</label>
                                  <select
                                    class="form-control"
                                    value={maritalStatus}
                                    onChange={(e) => setMaritalStatus(e.target.value)}
                                    disabled
                                  >
                                    <option>{detailsData?.marital_status ? detailsData?.marital_status : "--Select--"}</option>
                                    <option>Single</option>
                                    <option>Married</option>
                                    <option>Seperated</option>
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label>Religion</label>
                                  <select
                                    class="form-control"
                                    value={religion}
                                    onChange={(e) => setReligion(e.target.value)}
                                    disabled
                                  >
                                    <option>{detailsData.religion ? detailsData.religion :"--Select--"}</option>
                                    <option>Christianity</option>
                                    <option>Islam</option>
                                    <option>Others</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group row">
                                <div class="col-md-4">
                                  <label>Day of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.dob}
                                    class="form-control"
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                    disabled
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label>Month of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.mob}
                                    class="form-control"
                                    value={mob}
                                    onChange={(e) => setMob(e.target.value)}
                                    disabled
                                  />
                                </div>
                                <div class="col-md-4">
                                  <label>Year of Birth</label>
                                  <input
                                    type="number"
                                    placeholder={detailsData?.yob}
                                    class="form-control"
                                    value={yob}
                                    onChange={(e) => setYob(e.target.value)}
                                    disabled
                                  />
                                </div>
                              </div>
                              {/* <div class="form-group row">
                                <div class="col-md-4">
                                  <label>Country of Origin</label>
                                  <select
                                    class="form-control"
                                    onChange={handleCountry}
                                  >
                                    <option value="">
                                      {selectedCountry
                                        ? selectedCountry
                                        : "--Country--"}
                                    </option>
                                    {country.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label>State of Origin</label>
                                  <select
                                    class="form-control"
                                    onChange={handleState}
                                  >
                                    <option value="">
                                      {selectedState
                                        ? selectedState
                                        : "--State--"}
                                    </option>
                                    {state.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-4">
                                  <label>Local govt area</label>
                                  <select
                                    class="form-control"
                                    onChange={handleCity}
                                  >
                                    <option value="">
                                      {selectedCity ? selectedCity : "--LGA--"}
                                    </option>
                                    {cities.map((items) => (
                                      <option value={items} key={items}>
                                        {items}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div> */}
                              <div class="form-group">
                                <button
                                  class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                                  type="submit"
                                  // disabled={loading ? true : false}
                                  disabled
                                >
                                  Update Profile
                                </button>
                              </div>
                          </div>
                        </form>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal modal-rightbar fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="MetricaRightbar"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title mt-0" id="MetricaRightbar">
                  Appearance
                </h5>
                <button
                  type="button"
                  class="btn btn-sm btn-soft-primary btn-circle btn-square"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="modal-body">
                <ul
                  class="nav nav-pills nav-justified mt-2 mb-4"
                  role="tablist"
                >
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#ActivityTab"
                      role="tab"
                    >
                      Activity
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#TasksTab"
                      role="tab"
                    >
                      Tasks
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#SettingsTab"
                      role="tab"
                    >
                      Settings
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div
                    class="tab-pane active "
                    id="ActivityTab"
                    role="tabpanel"
                  >
                    <div class="bg-light mx-n3">
                      <img
                        src="../assets/images/small/img-1.gif"
                        alt=""
                        class="d-block mx-auto my-4"
                        height="180"
                      />
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="activity">
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-timer-off bg-soft-pink"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                50 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task Overdue
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                            <span class="badge badge-soft-secondary">
                              Design
                            </span>
                            <span class="badge badge-soft-secondary">HTML</span>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-alert-decagram bg-soft-purple"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 hours ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Task
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-warning"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                yesterday
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Comment
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-secondary"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                01 feb 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Lead Meting
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                26 jan 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="TasksTab" role="tabpanel">
                    <div class="m-0">
                      <div id="rightbar_chart" class="apex-charts"></div>
                    </div>
                    <div class="text-center mt-n2 mb-2">
                      <button type="button" class="btn btn-soft-primary">
                        Create Project
                      </button>
                      <button type="button" class="btn btn-soft-primary">
                        Create Task
                      </button>
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="p-2">
                        <div class="media mb-3">
                          <img
                            src="../assets/images/widgets/project3.jpg"
                            alt=""
                            class="thumb-lg rounded-circle"
                          />
                          <div class="media-body align-self-center text-truncate ml-3">
                            <p class="text-success font-weight-semibold mb-0 font-14">
                              Project
                            </p>
                            <h4 class="mt-0 mb-0 font-weight-semibold text-dark font-18">
                              Payment App
                            </h4>
                          </div>
                        </div>
                        <span>
                          <b>Deadline:</b> 02 June 2020
                        </span>
                        <a href="javascript: void(0);" class="d-block mt-3">
                          <p class="text-muted mb-0">
                            Complete Tasks<span class="float-right">75%</span>
                          </p>
                          <div class="progress mt-2" style={{ height: "4px" }}>
                            <div
                              class="progress-bar bg-secondary"
                              role="progressbar"
                              style={{ width: "75%" }}
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </a>
                      </div>
                      <hr class="hr-dashed" />
                    </div>
                  </div>
                  <div class="tab-pane" id="SettingsTab" role="tabpanel">
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">Account Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch1"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch1"
                        >
                          Auto updates
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch2"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch2"
                        >
                          Location Permission
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch3"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch3"
                        >
                          Show offline Contacts
                        </label>
                      </div>
                    </div>
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">General Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch4"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch4"
                        >
                          Show me Online
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch5"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch5"
                        >
                          Status visible to all
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch6"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch6"
                        >
                          Notifications Popup
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      }
    </div>
  );
}

export default UpdateBioData;
