import { useState } from "react";
import api from "../../../api/api";
import Footer from "../../../components/Footer";
import useDetails from "../../../hooks/useDetails";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import Loader from "../../../components/Loader";

const RemedialRegistration = () => {
  const { data } = useDetails();
  const paymentComplete = data?.payment_complete;

  const MAX_UNITS = 40;

  const [courses, setCourses] = useState();
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [totalRecords, setTotalRecords] = useState(0);
  const [courseCode, setCourseCode] = useState('')
  const [totalSelectedUnits, setTotalSelectedUnits] = useState(0);
  const [rowClick, setRowClick] = useState(true);
  const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const totalUnits = selectedCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  console.log("this is the pagination", pagination)

  const getCourses = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(
        `/courses/all/?p=${pagination + 1}&page_size=${pageSize}&code=${courseCode}`
      );
      console.log("Courses list", data);
      setCourses(data.data);
      setTotalRecords(data.count);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const onPageChange = (event) => {
    console.log(event, '<<<<<<<')
    setPagination(event.page); // API pagination typically starts at 1
  };

  const onRowsChange = (event) => {
    setPagination(1); // Reset to the first page when changing page size
    setPageSize(event.value);
  };

  const registerCourses = async (e) => {
    e.preventDefault();
    setLoading(true);

    const coursesId = selectedCourses?.map((item) => ({ course_id: item.id }));

    try {
      const { data } = await api.post("/remedial/register", {
        courses: coursesId,
      });
      console.log("Remedial registered courses", data);
      setLoading(false);
      window.location.replace(data.data.authorization_url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectionChange = (e) => {
    // Calculate the total units of the selected courses
    const newSelectedCourses = e.value;
    console.log("newSelec-->", newSelectedCourses);
    let totalUnits = 0;
    newSelectedCourses.forEach((theCourses) => {
      totalUnits += parseInt(theCourses.unit); // Assuming quantity represents units
    });

    // Check if the total units exceed the allowed maximum
    if (totalUnits <= MAX_UNITS) {
      setSelectedCourses(newSelectedCourses);
      setTotalSelectedUnits(totalUnits);
    } else {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units`);
    }
    // If the total units exceed the limit, don't update the selectedProducts state
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(_filters, '_filterrr')

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
    if (value.length > 5) {
      setCourseCode(value)
    }
};

  const renderHeader = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
    );
};

const header = renderHeader();

  useEffect(() => {
    getCourses();
  }, [pagination, pageSize, courseCode]);

  return (
    <div>
      <div class="page-wrapper">
        {(data && courses) && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">
                          Remedial Registration
                        </li>
                      </ol>
                    </div>
                    <h4 class="page-title">Remedials</h4>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="tab-content detail-list" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="general_detail">
                      <div class="row">
                        <div class="col-lg-12 col-xl-12 mx-auto">
                          <div class="card">
                            <div class="card-body">
                              <div class="container">
                                <div className="col-lg-12">
                                <h5 className="text-danger">Note: Please when searching, ensure you provide the full course code e.g ABC 123</h5>
                                  <DataTable
                                    value={courses}
                                    footer={`Total Units Selected: ${totalUnits}`}
                                    selectionMode={rowClick ? null : "checkbox"}
                                    selection={selectedCourses}
                                    onSelectionChange={handleSelectionChange}
                                    size='small'
                                    dataKey="id"
                                    tableStyle={{ minWidth: "50rem" }}
                                    loading={loading}
                                    header={header}
                                    filters={filters}
                                  >
                                    <Column
                                      selectionMode="multiple"
                                      headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                      field="code"
                                      header="Course Code"
                                    ></Column>
                                    <Column
                                      field="title"
                                      header="Course Title"
                                    ></Column>
                                    <Column
                                      field="unit"
                                      header="Course Unit"
                                    ></Column>
                                    <Column
                                      field="level"
                                      header="level"
                                    ></Column>
                                    <Column
                                      field="semester"
                                      header="Semester"
                                    ></Column>
                                  </DataTable>
                                  <Paginator first={pagination} rows={1} totalRecords={totalRecords} onPageChange={onPageChange} onRowsChange={onRowsChange} template=" PrevPageLink CurrentPageReport NextPageLink" />
                                  <div className="d-flex justify-content-between mt-2">
                                    <button
                                      className="btn waves-effect btn-danger"
                                      onClick={registerCourses}
                                    >
                                      Register Courses
                                    </button>
                                  </div>
                                </div>

                                {selectedCourses?.length ? (
                                  <div className="bg-light col-lg-9">
                                    <table className="mt-3 col-lg-12">
                                      <thead className="dspg-dark">
                                        <th>Code</th>
                                        <th>Course</th>
                                        <th>Unit</th>
                                        <th>Semester</th>
                                      </thead>
                                      {selectedCourses?.map((list) => (
                                        <tbody>
                                          <td>{list.code}</td>
                                          <td>{list.title}</td>
                                          <td>{list.unit}</td>
                                          <td>{list.semester}</td>
                                        </tbody>
                                      ))}
                                      <tfoot>
                                        <tr>
                                          <td className="h5">Total Units</td>
                                          <td></td>
                                          <td className="h5">{totalUnits}</td>
                                          <td></td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        )}
        {loading && <Loader/>}
      </div>
    </div>
  );
};

export default RemedialRegistration;
